import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import logo from "../../assets/css/images/foliologo.png"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import picture from "../../images/managed-infrastructure-large.png"

export default () => (
  <Layout isHome={false} pageId="folio_single_item" logo={logo}>
    <SEO title="Managed Infrastructure" />
    <div id="content">
      <div id="about_project">
        <h2 className="folio-title">Managed Infrastructure</h2>
        <div className="contact-intro">
          Build and maintain resilient infrastructure for your software. Whether
          you’re looking for someone to manage your existing servers, or
          planning to build new infrastructure, our team of dedicated engineers
          will keep you up and running.
        </div>

        <h5>Adaptable and secure hosting</h5>
        <p>
          We offer managed hosting with expert support. With our informed
          guidance, your servers will remain available and responsive, adapting
          readily to environmental changes. We’ll ensure that your application
          is well matched to its hosting platform – and with the ideal security
          posture.
        </p>
        <h5>Migrate your application to the cloud</h5>
        <p>
          Want to migrate your hosted environment, but not sure how to start?
          Our experienced team can help you select the correct platform, and
          migrate your servers, databases, and applications. We’re experts at
          lifting you to the clouds:
          <br />
          <strong>Google Cloud</strong>
          <br />
          <strong>Amazon AWS</strong>
          <br />
          <strong>Microsoft Azure</strong>
        </p>
        <ul className="navig">
          <li className="prev">
            <AniLink fade to="/solutions/ecommerce" rel="prev">
              Ecommerce
            </AniLink>
          </li>
          <li className="next">
            <AniLink fade to="/solutions/enterprise-security" rel="next">
              Enterprise Security
            </AniLink>
          </li>
        </ul>
      </div>

      <div id="preview">
        <div className="blogimg slide image_1 current">
          <img
            src={picture}
            className="attachment-large-folio-thumb size-large-folio-thumb"
            alt="Managed Infrastructure"
          />
        </div>
        <h5>In the clouds and close to the metal</h5>
        <p>
          All day, every day, our engineers are at the ready. Our experience
          working with major vendors and running our own infrastructure sets us
          apart. By providing expert oversight, we free you to concentrate on
          your business. Let us monitor, protect, and support your
          infrastructure — security comes standard.
        </p>
      </div>
    </div>
  </Layout>
)
